<template>
  <div
    class="pt-12 grey lighten-3"
    style="height:calc(100% - 50px);overflow:auto"
  >
    <v-card class="ma-4 px-4 py-4">
      <v-sheet
        color="primary"
        width="100%"
        elevation="6"
        class="px-4 py-2 v-card-material mb-n6 rounded"
        dark
      >
        <div class="v-card-material-title pt-1">
          个人信息录入
        </div>
        <div class="v-card-material-subtitle py-1">
          腹透远程系统
        </div>
      </v-sheet>
      <v-form v-model="valid" ref="form">
        <v-row no-gutters dense>
          <v-col cols="12">
            <v-chip
              class="elevation-2"
              color="primary"
              label
              text-color="white"
            >
              <v-icon left>
                mdi-label
              </v-icon>
              基本信息
            </v-chip>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="baseInfo.PNAME"
              label="姓名"
              :rules="[(v) => !!v || '请填写姓名！']"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              :items="['男', '女']"
              v-model="baseInfo.PSEX"
              label="性别"
              :rules="[(v) => !!v || '请选择性别！']"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              type="number"
              v-model="baseInfo.PAGE"
              label="年龄"
              :rules="[(v) => !!v || '请填写年龄！']"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="baseInfo.IDCARD"
              label="身份证"
              :rules="[(v) => !!v || '请填写身份证！']"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="baseInfo.PHONE"
              label="电话号码"
              :rules="[(v) => !!v || '请填写电话号码！']"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="baseInfo.PADDRESS"
              label="地址"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="commit" :loading="loading">
          提交个人信息
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog persistent :value="limitDialog">
      <v-card color="red" class="pa-4 text-center" dark>
        请在公众号中打开
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "UserInfo",
  data: () => ({
    loading: false,
    baseInfo: {
      IDCARD: undefined,
      PNAME: undefined,
      PSEX: undefined,
      PAGE: undefined,
      PHONE: undefined,
      PADDRESS: undefined,
    },
    valid: false,
    limitDialog: false,
  }),
  computed: {
    ...mapState(["openId", "currentUser"]),
  },
  mounted() {
    if (!this.openId || this.openId === "no_open_id") {
      this.limitDialog = true;
    }
    if (this.currentUser) {
      this.baseInfo = this.currentUser;
    }
  },
  methods: {
    ...mapMutations(["SET_CURRENTUSER"]),
    commit() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        if (this.currentUser && this.currentUser.ID) {
          this.update();
        } else {
          this.save();
        }
      }
    },
    save() {
      this.loading = true;
      this.$api
        .postAdd_NepUser({ ...this.baseInfo, WECHAT_OPENID: this.openId })
        .then((res) => {
          let data = JSON.parse(res);
          if (data.code !== 0) {
            this.$notify.error({
              message: data.message,
            });
            return;
          } else {
            //console.log(data);
            //let temp = JSON.parse(data.data || "{}");
            this.SET_CURRENTUSER({
              ...this.baseInfo,
              ID: data.data,
              WECHAT_OPENID: this.openId,
            });
            this.$notify.success({
              message: data.message,
            });
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error,
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.loading = true;
      this.$api
        .postU_NepUser({ ...this.baseInfo, ID: this.currentUser.ID })
        .then((res) => {
          let data = JSON.parse(res);
          if (data.code !== 0) {
            this.$notify.error({
              message: data.message,
            });
            return;
          } else {
            this.SET_CURRENTUSER({
              ...this.baseInfo,
              ID: this.currentUser.ID,
              WECHAT_OPENID: this.openId,
            });
            this.$notify.success({
              message: data.message,
            });
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error,
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.v-card-material {
  position: relative;
  top: -40px;
  transition: 0.3s ease;
  z-index: 1;
}
.v-card-material-title {
  font-size: 1.125rem;
}
.v-card-material-subtitle {
  font-size: 0.875rem;
  color: #ffffffaa;
}
.m_black {
  font-size: 16px;
  color: #000000a0;
}
.bg_4 {
  background: linear-gradient(87deg, #2dce89, #2dcecc) !important;
}
</style>
